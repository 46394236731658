<template>
	<div data-route>
		<page-header
			heading="Students"
		/>
		<div data-element="main">
			<table-view
				:table="getTable"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader    from '@/components/ui/PageHeader';
	import TableView     from '@/components/ui/views/tableView/TableView';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';
	import routeParams   from '@/mixins/routeParams';

	dayjs.extend(relativeTime);

	export default {
		metaInfo: {
			title: 'Students'
		},
		components: {
			PageHeader,
			TableView
		},
		mixins: [routeParams],
		data: () => ({
			course:   {},
			students: [],
			searchString: '',
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getTable () {
				if (!this.getCourseId) {
					return false;
				}
				return {
					headings: [
						'Name',
						'Joined',
						(!this.course?.live ? 'Progress' : false),
						(!this.course?.live ? 'Lessons' : false)
					].filter(Boolean),
					rows: this.students.map((student) => {
						return {
							route: `/teaching/courses/${this.getCourseId}/students/${student.id}`,
							columns: [
								this.getNameString(student),
								this.getJoinDateString(student),
								(!this.course?.live ? this.getProgress(student) : false),
								(!this.course?.live ? this.getLessonProgress(student) : false)
							].filter(Boolean)
						};
					})
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: 'Course'
					},
					{
						path: `${this.getCoursesRoute}/students`,
						text: 'Students'
					}
				];
			}
		},
		created: function () {
			this.loadContent();
		},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setStudents();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setStudents();
			},
			getNameString (student) {
				if (!student) {
					return false;
				}
				return `${student.firstName} ${student.lastName}`;
			},
			getJoinDateString (student) {
				if (!student) {
					return false;
				}
				return dayjs().to(student.joinDate);
			},
			getProgress (student) {
				if (!student) {
					return false;
				}
				return (student.progress === null) ? 'Incomplete' :
					(student.progress) ? 'Successfuly Completed' : 'Completed';
			},
			getLessonProgress (student) {
				if (!student) {
					return false;
				}
				return `Completed ${student.lessonProgress} of ${student.lessonCount}`;
			},
			async loadContent () {
				try {
					await Promise.all([
						this.setStudents(),
						this.setCourse()
					]);
				} catch {
					this.$store.commit('ui/showError');
					return;
				}
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async setStudents () {
				const response = await api.teaching.getStudentsByCourseId({
					courseId: this.getCourseId,
					include: 'metadata',
					searchString: this.searchString,
					per: this.pagination.perPage,
					page: this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.students = response.results;
			},
			async setCourse () {
				const course = await api.teaching.getCourseById({
					courseId: this.getCourseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.course = course;
			}
		}
	};

</script>
